import styled from "styled-components";

import { CartProductsStyledProps as Props } from "./CartProducts.types";

const CartProductsStyled = styled.div<Props>`
  .CartProducts {
  }
`;

export default CartProductsStyled;
