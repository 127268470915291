import { useStores } from "@sushicorp/contexts";
import { useShoppingCart as useShoppingCartContext } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { useProducts } from "@sushicorp/contexts";
import { getBenefitProductId } from "@sushicorp/utils";
import { events } from "artisn/analytics";
import { getShoppingCart, replaceProduct } from "artisn/shopping-cart";
import { useCallback, useMemo, useState } from "react";
import { flushSync } from "react-dom";

import { addToCartField, defaultConfig } from "./AddToCartButton.helpers";
import { UseAddToCartProps } from "./AddToCartButton.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";

const { CONTENT_TYPE, SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;
const { ACCOUNT_ID } = CONSTANTS.ARTISN;
const { logAddProductToCart, logUpdateProductInCart } = events.shoppingCart;

export const useAddToCart = (props: UseAddToCartProps) => {
  const { form, onFinish, config = defaultConfig, onError } = props;
  const { selectedStore: store } = useStores();
  const { selectedProduct, setSelectedProduct } = useProducts();
  const { temporalBenefit } = useShoppingCartContext();
  const { addProduct } = useShoppingCart();
  const { selectedCatalogueId } = useCatalogues();
  const { amount, comment } = config;
  const [isAdding, setIsAdding] = useState(false);
  const auth = useAuth();
  const { isAnonymous = false, uid } = auth;

  const shouldReplace = !!selectedProduct;
  const { product, validate } = form ?? {};

  const onClick = useCallback(async () => {
    if (!uid) return;
    if (!store) return;

    flushSync(() => {
      setIsAdding(true);
    });

    if (!product || !validate) {
      flushSync(() => {
        setIsAdding(false);
      });
      return;
    }

    const valid = validate() === "OK";
    if (!valid) {
      flushSync(() => {
        setIsAdding(false);
      });
      onError?.();
      return;
    }

    if (shouldReplace) {
      const { id, name, benefits } =
        (await getShoppingCart({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous,
          customerId: uid,
          accountId: ACCOUNT_ID
        })) ?? {};

      const selectedBenefit = benefits ? benefits[0] : undefined;
      const benefitProductId = getBenefitProductId(selectedBenefit);
      /* If the product being modified is not equal to the benefitId in the
        cart, replace it and continue normally. If not, do nothing. */
      if (benefitProductId !== product.productId) {
        await replaceProduct(product, {
          amount,
          comment,
          store,
          shoppingCartName: name,
          anonymous: isAnonymous,
          accountId: ACCOUNT_ID,
          customerId: uid
        });
        setSelectedProduct(undefined);

        if (store && id && name) {
          logUpdateProductInCart({
            cartId: id,
            cartName: name,
            product,
            store: store,
            fields: addToCartField,
            contentType: CONTENT_TYPE
          });
        }
      }
    } else {
      let id = undefined;
      let name = undefined;

      /* If a benefit of type PRODUCT exists in context, prevent addProduct
        from firing up. If this condition is removed, a benefit product is added
        first and another product is added to the cart too, removing the
        benefitId key from the product and breaking the coupons functionality.
      */
      if (!temporalBenefit) {
        const added = await addProduct?.({ product, amount, store });
        const { id: cartId, name: cartName } = added ?? {};
        id = cartId;
        name = cartName;
      }

      /* The add product to cart event is kept for coupons because in the
      background, the applyBenefit function runs addProduct too. */
      if (store && id && name) {
        logAddProductToCart({
          cartId: id,
          cartName: name,
          product,
          store: store,
          contentType: CONTENT_TYPE,
          product_catalog_id: selectedCatalogueId
        });
      }
    }

    await onFinish?.();

    flushSync(() => {
      setIsAdding(false);
    });
  }, [
    uid,
    store,
    product,
    validate,
    shouldReplace,
    onFinish,
    onError,
    isAnonymous,
    amount,
    comment,
    setSelectedProduct,
    temporalBenefit,
    selectedCatalogueId,
    addProduct
  ]);

  return useMemo(() => {
    return { shouldReplace, onClick, isAdding };
  }, [isAdding, onClick, shouldReplace]);
};
